<template>
  <v-dialog v-model="dialog" persistent width="400">
    <v-card class="d-flex flex-column" style="height: 100%">
      <v-card-title
        style="display:flex; flex-direction: row; justify-content:space-between;"
      >
        <span class="headline">Auto Time Track</span>
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text
        class="pb-0"
        style="align-items: center; display: flex; flex-direction: column;"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="d-flex flex-column"
              style="align-items: baseline"
            >
              <label class="app-bold-font mb-3">Date</label>
              <div
                class="app-medium-font dark-gray-color"
                :class="$vuetify.breakpoint.xs ? 'font-16' : 'font-18'"
              >
                {{ startDateStr }}
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-column"
              style="align-items: baseline"
            >
              <label class="app-bold-font mb-3">Shift</label>
              <v-select
                v-model="shift"
                :items="responses"
                label="Shift"
                item-text="shift.title"
                item-value="_id"
                style="width: 100%;"
                outlined
                dense
                solo
                :disabled="running"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-column justify-center align-center"
              style="align-items: baseline; color: #6038f7; font-family: Poppins-SemiBold; font-size: 50px;"
            >
              {{ formatSeconds() }}
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-column"
              style="align-items: baseline"
            >
              <label class="app-bold-font mb-3">Memo</label>
              <v-textarea
                v-model="memo"
                rows="2"
                counter="140"
                label="Short description of the work done"
                solo
                outlined
                style="width: 100%;"
                @input="onChange"
                :disabled="running"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="d-flex flex-row justify-center mb-2">
        <v-btn
          color="white darken-1"
          @click="onClose"
          :width="$vuetify.breakpoint.xs ? '45%' : 150"
          :small="$vuetify.breakpoint.xs"
        >
          Close
        </v-btn>
        <v-btn
          color="#b5e539"
          :loading="loading"
          @click="startTime"
          :width="$vuetify.breakpoint.xs ? '45%' : 150"
          :small="$vuetify.breakpoint.xs"
          :disabled="!memo || !shift"
        >
          {{ !running ? "Start" : "Stop" }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import dateFormat from "dateformat";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    need_id: String,
    onCloseDialog: {
      type: Function,
    },
  },
  data() {
    return {
      startDate: null,
      date_picker: false,
      timeslot: null,
      loading: false,
      snackbar: false,
      snackMessage: null,
      error: null,
      running: false,
      startDateStr: "Dec 31, 2021",
      pickerDate: null,
      today: null,
      memo: "",
      shift: null,
      timerSeconds: 0,
      responses: [],
    };
  },
  methods: {
    ...mapActions("timeslot", {
      startTimeTracking: "startTimeTracking",
      stopTimeTracking: "stopTimeTracking",
      getTimeTracking: "getTimeTracking",
    }),
    onClose() {
      this.memo = "";
      this.running = false;
      this.onCloseDialog();
    },
    onChange() {
      this.error = null;
    },
    startCounting() {
      if (this.running) {
        this.running = false;
      } else {
        this.running = true;
        this.plusSecond();
      }
    },
    plusSecond() {
      if (this.running) {
        this.timerSeconds++;
        setTimeout(this.plusSecond, 1000);
      }
    },
    formatSeconds() {
      var hours = Math.floor(this.timerSeconds / 3600);
      var minutes = Math.floor((this.timerSeconds / 60) % 60);
      var seconds = Math.floor(this.timerSeconds % 60);
      var timeStr = "";
      if (hours > 9) timeStr = `${hours}:`;
      else timeStr = `0${hours}:`;
      if (minutes > 9) timeStr += `${minutes}:`;
      else timeStr += `0${minutes}:`;
      if (seconds > 9) timeStr += `${seconds}`;
      else timeStr += `0${seconds}`;
      return timeStr;
    },
    startTime() {
      this.error = null;
      if (!this.running) {
        this.loading = true;
        this.startTimeTracking({
          memo: this.memo,
          response_id: this.shift,
          user_id: this.profile._id,
        })
          .then((res) => {
            this.timeslot = res;
            this.loading = false;
            this.startCounting();
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.snackbar = true;
            this.snackMessage = error.response.data.message;
          });
      } else {
        this.loading = true;
        this.stopTimeTracking({
          _id: this.timeslot._id,
        })
          .then((res) => {
            console.log(res);
            this.loading = false;
            this.onClose();
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.snackbar = true;
            this.snackMessage = error.response.data.message;
          });
      }
    },
    convertTZ(date, tzString) {
      if (!date) {
        return new Date(
          new Date().toLocaleString("en-US", {
            timeZone: tzString ? tzString : "America/New_York",
          })
        );
      }
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    getTimerInfo() {
      this.loading = true;
      this.getTimeTracking({ need_id: this.need_id })
        .then((res) => {
          this.loading = false;
          if (!res._id) {
            this.running = false;
            this.timerSeconds = 0;
            this.responses = [...res.responses];
            for (let i = 0; i < this.responses.length; i++) {
              if (!this.responses[i].shift.title) {
                this.responses[i].shift.title = `Shift ${i + 1}`;
              }
            }
          } else {
            this.running = false;
            this.timeslot = res;
            var startTime = this.convertTZ(this.timeslot.start, this.timezone);
            var currentTime = this.convertTZ("", this.timezone);
            this.timerSeconds = (currentTime - startTime) / 1000;
            this.responses = [...res.responses];
            for (let i = 0; i < this.responses.length; i++) {
              if (!this.responses[i].shift.title) {
                this.responses[i].shift.title = `Shift ${i + 1}`;
              }
            }
            this.shift = this.timeslot.response_id;
            this.memo = this.timeslot.memo;
            setTimeout(this.startCounting, 1000);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.snackMessage = error.response.data.message;
          this.snackbar = true;
        });
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      profileType: "type",
    }),
    ...mapGetters({
      timezone: "usersetting/getTimeZone",
    }),
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.getTimerInfo();
        this.today = this.convertTZ("", this.timezone);
        this.startDateStr = dateFormat(this.today, "dddd, mmmm d, yyyy");
      }
    },
  },
  mounted() {
    this.today = this.convertTZ("", this.timezone);
    this.startDateStr = dateFormat(this.today, "dddd, mmmm d, yyyy");
    this.getTimerInfo();
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  div >>> .v-dialog {
    margin: 0;
    /* padding-top: 17px; */
    /* padding-bottom: 17px; */
    /* height: 100%; */
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
  div >>> .v-dialog {
    /* height: 80vh; */
    width: 70%;
    max-width: 900px;
    overflow-y: hidden;
  }
}
</style>
